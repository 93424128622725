.vui-modal-alert {
  .ant-modal-close-x{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 69px;
    width: 69px;
    svg{
      width: 22px;
      height: 22px;
      fill: #232D3B;
    }
  }
  .ant-modal-header{
    padding: 20px 24px;
    .ant-modal-title{
      line-height: 32px;
      font-size: 22px;
    }
  }
  .ant-modal-body{
    .ant-space-vertical{
      width: 100%;
    }
    .title{
      margin-bottom: 4px;
    }
    .text-wrapper{
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .info{
      color: #FF4D4F;
      font-style: italic;
      margin-top: 6px;
    }
  }
  .image-wrapper {
    text-align: center;
    img {
      width: 70%;
    }
  }
}

@primary-color: #067ECA;