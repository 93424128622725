@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

.container {
  width: 100%;
  margin: auto;

  @media (max-width: @screen-md){
    padding: 0 1rem;
  }
}

.make-container(@minWidth, @breakpoint) {
  @media (min-width: @minWidth) {
    .container {
      max-width: @breakpoint;
    }
  }
}

.make-container(@screen-xs-min, @screen-xs);
.make-container(@screen-sm-min, @screen-sm);
.make-container(@screen-md-min, @screen-md);
.make-container(@screen-lg-min, @screen-lg);
.make-container(@screen-xl-min, @screen-xl);

img {
  width: 100%;
}

#auth-layout {
  .container-wrapper {
    padding: 0px 50px 0px 50px;
    background: white;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
  }
  .header {
    background: white;
    padding: 0px;
  }
  .content-wrapper {
    flex: none;
  }
  .content {
    margin: auto;
    text-align: center;

    .illustration {
      width: 100%;
    }
  }
  .form-wrapper {
    background-color: rgba(6, 126, 202, 0.1);
    padding: 30px;
    border-radius: 10px;

    .title {
      font-weight: 500;
      span {
        font-weight: bold;
        color: @primary-color;
      }
    }

    .form-footer {
      .form-footer-text {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .footer {
    text-align: center;
    background: white;
    color: rgba(0, 0, 0, 0.4);
  }

  .error-wrapper {
    width: 100%;

    .error-main-wrapper {
      max-width: 550px;
      margin: auto;
      text-align: center;

      .illustration {
        width: 70%;
        margin-bottom: 15px;
      }
    }
  }

  @media only screen and (min-width: @screen-lg) {
    .container-wrapper {
      padding: 0px 139px 0px 139px;
    }
  }
}

#app-layout {
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(131, 146, 165);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(131, 146, 165);
  }

  .container-wrapper {
    padding-top: 60px;
    min-height: calc(100vh - 40px);
  }

  .navigation-vertical-wrapper {
    position: fixed;
    top: 60px;
    height: calc(100% - 60px);
    width: 240px;
    overflow-y: scroll;

    .ant-menu-inline.ant-menu-root .ant-menu-item {
      margin: 0px;
    }
  }

  @media only screen and (max-width: @screen-lg) {
    .container-wrapper {
      padding-top: 50px;
      min-height: calc(100vh - 72px);
    }
  }
}

.extended-main-wrapper {
  .main-content {
    margin-left: 60px;
    min-height: 100vh;
  }

  @media only screen and (max-width: @screen-lg) {
    display: flex;
    flex-direction: column;
    position: relative;

    .main-content {
      margin-left: 0px;
    }
  }
}

.show-lg {
  display: none;
}

.hide-lg {
  display: block;
}

@media only screen and (min-width: @screen-lg) {
  .show-lg {
    display: block;
  }
  .hide-lg {
    display: none;
  }
}

.text-capitalize {
  text-transform: capitalize;
}
