.ant-spin-nested-loading{
  &.w-fit-content{
    width: fit-content;
  }
}

.vui-button{
  &.ant-btn{
    display: flex;
    align-items: center;
    padding: 8px 28px;
    height: 40px;
    justify-content: center;

    svg{
      margin-right: 8px;
    }

    &.ant-btn-sm{
      padding: 6px 18px;
      min-width: 101px;
    }

    span{
      text-transform: unset;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  &.ant-btn-default{
    border-color: @primary-color;
    &:hover{
      color: @primary-color;
    }
  }

  &.ant-btn-primary{
    color: #FFF;
    border-color: @primary-color;
    background: @primary-color;
  }
}

@primary-color: #067ECA;